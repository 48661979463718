/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/Layout";
import RichText from "../components/RichText";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

const FacilitationArticle = ({
  pageContext: {
    article: { uid, title, body, seo },
  },
}) => (
  <Layout
    title={seo.title || title}
    description={seo.description}
    breadcrumbsMap={{
      [uid]: title,
    }}
  >
    <div sx={{ maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`] }}>
      <RichText render={body} />
    </div>
  </Layout>
);

export default FacilitationArticle;
